// 挂载、激活App
import Vue from 'vue'
import createApp from './app'

const {app, router, store} = createApp()

// 路由变化时刷新数据
Vue.mixin({
    beforeRouteUpdate (to, from, next) {
        const { asyncData } = this.$options

        if (asyncData) {
            asyncData({
                store: store,
                route: to
            }).then(next).catch(next)
        } else {
            next()
        }
    }
})

router.onReady(()=>{

    if (window.__INITIAL_STATE__) {
        store.replaceState(window.__INITIAL_STATE__)
    }



    app.$mount('#app')
})
