<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
    export default {
        name: "App",
        created(){

        },
    }
</script>

<style lang="scss">
  *{
    margin: 0;
  }

</style>
