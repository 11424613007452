<template>
    <div class="article_list"
         v-loading="$store.state.article_list.is_loading">

        <div class="page_content_title">{{ title }}</div>

        <div v-if="article_list.data && article_list.data.length>0" class="article_list">

            <router-link target="_blank" :to="{name:'article_detail', params:{id:item.id } }"   class="article_item" v-for="(item, index) in article_list.data" :key="index">
                <div class="article_item_img_wrap">
                    <img class="article_item_img" :src="item.img" alt="">
                </div>

                <div class="article_item_info">
                    <div class="article_item_title_wrap">
                        <!--<div class="article_item_title_lab">{{ article_list.type_name }}</div>-->
                        <a class="article_item_title">{{ item.title }}</a>
                    </div>

                    <div class="article_item_desc">{{ item.desc }}</div>

                    <div class="article_item_date">{{item.date}} <div class="article_item_date_block"/> 推荐：{{ item.author }} <div class="article_item_date_block"/> 阅读：{{ item.view_count }}</div>

                </div>
            </router-link>

            <link-page :pageInfo="{ total:article_list.total, current:article_list.current_page, pagenum:article_list.per_page, pagegroup:article_list.per_page, skin:'#ff5c00', base_link:'/article_list/'+article_list.type+'/$page_index' }"/>

        </div>

        <div class="not_article_list_wrap" v-else>
            <div class="not_article_list">
                <i class="el-icon-news not_article_list_icon"></i>
                <div>当前列表为空~</div>
            </div>
        </div>

    </div>
</template>

<script>

    import LinkPage from '../components/LinkPage'

    export default {
        name: "PageArticleList",
        props: {
            title: {
                type: String
            },
            article_list: {},
        },

        created(){

        },

        components: {
            LinkPage,
        },
    }
</script>

<style lang="scss" scoped>

    .article_list{
        width: 780PX;
        min-height: 700PX;




        .page_content_title{
            height: 60PX;
            margin-top: 10PX;
            color: #0E0508;
            font-size: 21PX;
            font-weight: bold;

            display: flex;
            align-items: center;

            border-bottom: solid 1px #F2F2F2;
        }

        .article_list{
            width: 100%;

            a{
                text-decoration: none;
            }

            .article_item{
                width: 780PX;
                height: 175PX;

                display: flex;
                align-items: center;
                cursor: pointer;

                .article_item_img_wrap{
                    width: 217PX;
                    height: 138PX;
                    overflow: hidden;
                    .article_item_img{
                        width: 217PX;
                        height: 138PX;
                    }
                }


                .article_item_info{
                    height: 138PX;
                    margin-left: 34PX;
                    position: relative;

                    .article_item_title_wrap{
                        display: flex;
                        align-items: center;
                        .article_item_title_lab{
                            padding-left: 10PX;
                            padding-right: 10PX;
                            height: 26PX;
                            border:1px solid rgba(255,92,0,1);
                            border-radius:3px;

                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #FF5C00;
                            font-size: 12PX;
                        }
                        .article_item_title{
                            width: 400PX;
                            /*margin-left: 10PX;*/
                            color: black;
                            font-size: 18PX;
                            font-weight: 800;

                            overflow: hidden;
                            text-overflow:ellipsis;
                            white-space: nowrap;
                        }
                        .article_item_title:hover{
                            color: #FF5C00;
                        }
                    }

                    .article_item_desc{
                        width: 500PX;
                        color: #888888;
                        font-size: 14PX;

                        margin-top: 20PX;

                        display:-webkit-box;
                        overflow:hidden;
                        text-overflow:ellipsis;
                        -webkit-box-orient:vertical;
                        -webkit-line-clamp:2;
                    }

                    .article_item_date{
                        color: #888888;
                        font-size: 12PX;
                        position: absolute;
                        bottom: 10PX;

                        display: flex;

                        .article_item_date_block{
                            width: 15PX;
                        }
                    }

                }
            }
            .article_item:hover img{
                transition: all 0.5s;
                transform: scale(1.3);
            }
        }

        .not_article_list_wrap{
            width: 100%;
            height: 100%;
            min-height: 500PX;

            display: flex;
            justify-content: center;
            align-items: center;

            .not_article_list{
                display: flex;
                align-items: center;

                font-size: 20PX;
                color: rgba($color: #000000, $alpha: 0.2);

                .not_article_list_icon{
                    margin-right: 10PX;
                    font-size: 30PX;
                }
            }
        }
    }

</style>
