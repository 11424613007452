<template>
    <div class="right_content">
        <img class="right_ad" src="../assets/Home/right_ad.png" alt="">

        <div :class="fixed_content">
            <div class="hot_articles_wrap">

                <div class="right_content_title_wrap">
                    <div class="right_content_title">热门文章</div>
                </div>

                <div class="hot_articles">

                    <router-link target="_blank" :to="{name:'article_detail', params:{id:item.id } }" class="hot_article_item" v-for="(item, index) in article_recommend_list.data" :key="index">
                        <div class="hot_article_item_icon_wrap">
                            <img class="hot_article_item_icon" :src="item.img" alt="">
                        </div>

                        <div class="hot_article_item_info">
                            <div class="hot_article_item_title">{{ item.title }}</div>
                            <div class="hot_article_item_btn">查看详情</div>
                        </div>
                    </router-link>

                </div>

                <div class="hot_articles_btn">
                    <router-link :to="{name:'article_list', params:{type:'short_video_lesson_recommend', page:1} }">查看全部 ></router-link>
                </div>

            </div>

            <div class="hot_labs_wrap">

                <div class="right_content_title_wrap">
                    <div class="right_content_title">热门分类</div>
                </div>

                <div class="hot_labs">

                    <div class="hot_lab" v-for="(item, index) in right_lab_list" :key="index">
                        <router-link :to="{name:'article_list', params:{type:item.type, page:1} }">{{ item.type_name }}</router-link>
                    </div>

                </div>

            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "RightContent",
        data(){
            return {
                fixed_content: '',
            }
        },
        props: {
            article_recommend_list: {},
            right_lab_list: {},
        },

        mounted(){
            window.addEventListener('scroll', this.onPageScroll, true)
        },

        destroyed(){
            window.removeEventListener('scroll', this.onPageScroll, true)
        },

        methods: {

            getScrollTop(){
                try {
                    let scrollTop = document.documentElement.scrollTop ||
                        window.pageYOffset ||
                        document.body.scrollTop ||
                        document.querySelector(this.el).scrollTop;
                    return scrollTop
                }catch (e) {return 0}

            },

            onPageScroll(e){
                let scrollTop = this.getScrollTop()

                let is_near_bottom_content = (document.body.scrollHeight - scrollTop) < 1200;

                // console.log(document.body.scrollHeight, scrollTop, document.body.scrollHeight - scrollTop, is_near_bottom_content)
                if(!is_near_bottom_content && scrollTop > 800 && document.body.scrollHeight > 2500){
                    this.fixed_content = "fixed_content"
                }else if(is_near_bottom_content){
                    this.fixed_content = "bottom_content"
                }else{
                    this.fixed_content = ""
                }
            },

        },
    }
</script>

<style lang="scss" scoped>

    .right_content{
        width: 265PX;

        position: relative;

        .right_ad{
            width: 266PX;
            height: 464PX;
            border:1px solid rgba(238,238,238,1);
            cursor: pointer;
        }

        .right_content_title_wrap{
            width: 100%;
            height: 45PX;

            color: #FF5C00;
            font-size: 18PX;
            font-weight: 400;

            display: flex;
            align-items: center;
            justify-content: center;

            .right_content_title{
                width: 230PX;
                height: 100%;
                display: flex;
                align-items: center;
                border-bottom:1px solid rgba(238,238,238,1);
            }
        }

        .fixed_content{
          position: fixed;
          top: -15px;
        }

        .bottom_content{
            position: absolute;
            bottom: 20PX;
        }

        .hot_articles_wrap{
            width: 266PX;
            border:1px solid rgba(238,238,238,1);
            cursor: pointer;
            margin-top: 25PX;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            background: white;

            .hot_articles{
                width: 230PX;
                margin-top: 18PX;

                a{
                    text-decoration: none;
                }

                .hot_article_item{
                    width: 230PX;
                    height: 67PX;
                    margin-bottom: 18PX;
                    cursor: pointer;

                    display: flex;
                    .hot_article_item_icon_wrap{
                        width: 91PX;
                        height: 67PX;
                        overflow: hidden;

                        .hot_article_item_icon{
                            width: 91PX;
                            height: 67PX;
                        }
                    }


                    .hot_article_item_info{
                        width: 126PX;
                        height: 100%;
                        margin-left: 17PX;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .hot_article_item_title{

                            color: #0E0508;
                            font-size: 14PX;
                            font-weight: 600;

                            display:-webkit-box;
                            overflow:hidden;
                            text-overflow:ellipsis;
                            -webkit-box-orient:vertical;
                            -webkit-line-clamp:2;
                        }
                        .hot_article_item_title:hover{
                            color: #FF5C00;
                        }

                        .hot_article_item_btn{
                            width: 56PX;
                            height: 21PX;
                            border: solid 1PX #FF5C00;

                            color: #FF5C00;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            font-size: 12PX;
                            transform: scale(0.9);
                        }
                    }
                }

                .hot_article_item:hover img{
                    transition: all 0.5s;
                    transform: scale(1.3);
                }

            }

            .hot_articles_btn{
                width: 232PX;
                height: 42PX;

                display: flex;
                justify-content: center;
                align-items: center;

                background: #FAFAFA;
                color: #888888;
                font-size: 14PX;


                margin-bottom: 18PX;
            }

            .hot_articles_btn a{
                text-decoration: none;
                color: #888888;
            }
        }

        .hot_labs_wrap{
            width: 266PX;
            border:1px solid rgba(238,238,238,1);
            cursor: pointer;
            margin-top: 25PX;

            background: white;

            .hot_labs{
                width: 230PX;
                display: flex;
                flex-wrap: wrap;

                margin-left: 10PX;
                margin-top: 10PX;
                margin-bottom: 10PX;

                .hot_lab{
                    padding-top: 3PX;
                    padding-bottom: 3PX;
                    padding-left: 5PX;
                    padding-right: 5PX;

                    background: #FAFAFA;

                    color: #888888;
                    margin-left: 6PX;
                    margin-top: 5PX;
                    margin-bottom: 5px;

                    font-size: 12PX;

                }
                .hot_lab:hover{
                    color: white;
                    background: #FF5C00;
                }

                .hot_lab a{
                    text-decoration: none;
                    color: #888888;
                }

                .hot_lab:hover a{
                    color: white;
                }
            }
        }
    }

</style>
