import { articleDetail } from '../../api'

export default {

    namespaced: true,
    // 重要信息：state 必须是一个函数，
    // 因此可以创建多个实例化该模块
    state: () => ({
        article_detail: {},
        is_loading: false,
    }),

    actions: {

        fetchArticleDetail({ commit }, route){

            let id = route.params.id ? route.params.id : 1

            return articleDetail({id: id}).then(({data, status}) => {
                commit('updateArticleDetail', data.data.article)
            }).catch((e)=>{
                commit('updateArticleDetail', null)
            }).finally(()=>{
                commit('updateIsLoading', false)
            })
        },

    },

    mutations: {
        updateArticleDetail: (state, data) => state.article_detail = data,

        updateIsLoading(state, isloading) {
            state.is_loading = isloading;
        },

    }
}
