<template>
    <div class="labs_card">
        <div class="labs_wrap">
            <div class="labs left_labs">
                <router-link class="lab" :to="{name:'article_list', params:{type:item.type, page:1} }"  v-for="(item, index) in article_hot_lab_list" :key="index">
                    <img class="lab_icon" src="../assets/Home/hot.png" alt="">
                    <div class="lab_text">{{ item.type_name }}</div>
                </router-link>
            </div>

            <div class="labs_line"></div>

            <div class="labs right_labs">
                <router-link class="lab" :to="{name:'article_list', params:{type:item.type, page:1} }" v-for="(item, index) in article_lab_list" :key="index">
                    <div class="lab_text">{{ item.type_name }}</div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LabsCard",
        props: {
            article_hot_lab_list: {},
            article_lab_list: {},
        }
    }
</script>

<style lang="scss" scoped>

    .labs_card{
        width: 100vw;
        display: flex;
        justify-content: center;

        a{
            text-decoration: none;
            color: black;
        }

        .labs_wrap{
            width: 1050PX;
            height: 112PX;
            margin-top: 30PX;
            box-shadow:0px 11px 21px 0px rgba(136,136,136,0.08);
            display: flex;
            align-items: center;

            .labs{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                .lab{
                    width: 100PX;
                    display: flex;
                    align-items: center;
                    margin-top: 6PX;
                    margin-bottom: 6PX;
                    margin-left: 40PX;
                    cursor: pointer;
                    .lab_icon{
                        width: 20PX;
                        height: 20PX;
                        margin-right: 2PX;
                    }
                    .lab_text{
                        color: #222222;
                        font-size: 14PX;
                    }
                }
            }

            .left_labs{
                width: 320PX;
                /*background: antiquewhite;*/
            }

            .right_labs{
                width: 730PX;
            }

            .labs_line{
                width: 1PX;
                height: calc(100% - 60px);
                background: rgba($color: #BFBFBF, $alpha: 0.6);
            }
        }

    }

</style>
