<template>
    <div class="content_wrap">

    </div>
</template>

<script>


    export default {
        name: "Home",

        asyncData({store, route}){
            store.commit('article_list/updateIsLoading', true)
            return Promise.all([
                store.dispatch("article_list/fetchArticleList", route, store),
            ])
        },


        metaInfo: {
            title: '小卓学院_短视频代运营_抖音代运营_新媒体代运营_凡卓传媒',
            meta: [
                {
                    name: 'keyWords',
                    content: '短视频，短视频代运营，短视频营销，视频营销，抖音代运营，新媒体代运营，新媒体运营，凡卓传媒'
                },
                {
                    name: 'Description',
                    content: '凡卓传媒致力于打造优质短视频内容，培养优质的短视频内容创造者。我们已形成与各大短视频、新媒体等流量平台的战略合作，合作关系紧密、信任度高，为我们的客户提供了丰富的流量 选择及商业转化基于大规模的优质短视频内容产出经验，以及跟各大自媒体平台的深入合作与分析，我们发展了包括：短视频内容营销、网红电商等变现模式，并形成一体化生态的业务体系。'
                }
            ]
        },
    }
</script>

<style lang="scss" scoped>
    .content_wrap{
        width: 750px;
    }
</style>
