<template>
    <div class="navigation_carousel">
        <Navigation  class="navigation"/>

        <div v-show="!$isServer" class="loop_wrap">
            <swiper class="loop" :options="top_swiper_option">
                <swiper-slide v-for="(item,index)  in loop" :key="index">
                    <div class="carousel_item">
                        <img class="carousel_item_img" :src="item.img" alt="">
                    </div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NavigationCarousel",

        data(){
            return {
                top_swiper_option: {
                    loop: true,
                    autoplay: {
                        delay: 4000, //自动切换的时间间隔，单位ms
                    },
                    pagination: {
                        el: ".swiper-pagination",
                        dynamicBullets: true,
                        clickable: true,
                        renderBullet: function (index, className) {
                            return '<span class="' + className + '" style="width: 30PX; height: 12PX; border-radius: 360px; background: #FFFFFF;"></span>';
                        }

                    },

                },

                loop: [
                    {
                        img: require('../assets/Home/loop1.jpg'),
                        link: 'http://p.qiao.baidu.com/cps/chat?siteId=13654282&userId=28454994&cp=PC&cr=guanwang&cw=lunbotu',
                    },
                    {
                        img: require('../assets/Home/loop2.jpg'),
                        link: 'http://p.qiao.baidu.com/cps/chat?siteId=13654282&userId=28454994&cp=PC&cr=guanwang&cw=lunbotu',
                    },
                    {
                        img: require('../assets/Home/loop3.jpg'),
                        link: 'http://p.qiao.baidu.com/cps/chat?siteId=13654282&userId=28454994&cp=PC&cr=guanwang&cw=lunbotu',
                    },
                ],
            }
        },

    }
</script>

<style lang="scss" scoped>

    .navigation_carousel{

        .navigation{
            position: absolute;
            z-index: 999;
        }

        .loop_wrap{

            .loop{
                .carousel_item{
                    height: 455PX;

                    display: flex;
                    justify-content: center;
                    overflow: hidden;

                    .carousel_item_img{
                        width: auto;
                        height: 100%;

                    }
                }
            }
        }
    }

</style>
