<script src="../../../../basic/qukuai_webview_test/unijs_bridge.js"></script>
<template>
    <div class="content_wrap">

        <navigation-carousel/>

        <labs-card
                :article_hot_lab_list="article_hot_lab_list"
                :article_lab_list="article_lab_list"/>

        <div class="main_content">

            <div class="page_content">

                <search-article/>


                <div v-if="article_detail" class="article_detail" v-loading="$store.state.article_detail.is_loading">

                    <div class="article_detail_title">{{ article_detail.title }}</div>

                    <div class="article_detail_info">作者：{{ article_detail.author }} | {{ article_detail.date }} | 阅读：{{ article_detail.view_count }}</div>

                    <div class="article_detail_line"></div>

                    <div class="article_detail_content" v-html="article_detail.content"></div>

                </div>

                <div class="not_article_wrap" v-else>
                    <div class="not_article">
                        <i class="el-icon-news not_article_icon"></i>
                        <div>当前文章不存在~</div>
                    </div>
                </div>


            </div>

            <right-content :article_recommend_list="article_recommend_list" :right_lab_list="right_lab_list"/>
        </div>

        <page-footer/>

    </div>
</template>

<script>
    import { mapState } from 'vuex'


    import NavigationCarousel from '../components/NavigationCarousel'
    import LabsCard from '../components/LabsCard'
    import SearchArticle from '../components/SearchArticle'
    import PageArticleList from '../components/PageArticleList'
    import RightContent from '../components/RightContent'

    export default {
        name: "ArticleDetail",

        data(){
            return{
                isloading: false,
                page_meta_info: {
                    title: '',
                    keyWords: '',
                    Description: '',
                }
            }
        },

        created(){

        },

        components: {
            NavigationCarousel,
            LabsCard,
            SearchArticle,
            RightContent,
        },



        asyncData({store, route}){
            store.commit('article_detail/updateIsLoading', true)
            return Promise.all([
                store.dispatch("article_list/fetchArticleRecommendList"),
                store.dispatch("article_detail/fetchArticleDetail", route, store),
            ])

        },

        watch:{
            article_detail(){
                if(this.article_detail){
                    this.page_meta_info.title = this.article_detail.title + ' - 小卓运营官'
                    this.page_meta_info.keyWords = this.article_detail.desc + ',' + this.article_detail.title
                    this.page_meta_info.Description = this.article_detail.desc
                }
            },
        },


        computed: {
            ...mapState({
                right_lab_list: state => {
                    return state.lab_list
                },
                article_hot_lab_list: state => {
                    return state.lab_list.slice(0, 4)
                },
                article_lab_list: state => {
                    return state.lab_list.slice(4, state.lab_list.length)
                },
                article_recommend_list: state => {
                    if(state.article_list.article_recommend_list.data == undefined || state.article_list.article_recommend_list.data.length < 5){
                        return state.article_list.article_recommend_list
                    }else{
                        let article_recommend_list = JSON.parse(JSON.stringify(state.article_list.article_recommend_list))
                        article_recommend_list.data = article_recommend_list.data.slice(0, 4)
                        return article_recommend_list
                    }
                },

                article_detail: state => {
                    return state.article_detail.article_detail
                },

            }),

        },

        destroyed () {

        },


        metaInfo () {
            return {
                title: this.page_meta_info.title,
                meta: [
                    {
                        name: 'keyWords',
                        content: this.page_meta_info.keyWords
                    },
                    {
                        name: 'Description',
                        content: this.page_meta_info.Description
                    }
                ]
            }
        },

    }
</script>

<style lang="scss" scoped>
    .content_wrap{
        width: 100vw;

        .center_content{
            width: 100%;
            display: flex;
            justify-content: center;
        }


        .main_content{
            display: flex;
            flex-direction: row;
            justify-content: center;

            margin-top: 37PX;

            .page_content{
                width: 780PX;

                .article_detail{
                    width: 780PX;
                    margin-top: 45PX;

                    .article_detail_title{
                        color: #111111;
                        font-size: 28PX;
                        font-weight: 600;
                    }

                    .article_detail_info{
                        color: #616161;
                        font-size: 14PX;
                        margin-top: 20PX;
                    }

                    .article_detail_line{
                        width: 744PX;
                        height: 1PX;
                        background: rgba($color: #CDCDCD, $alpha: 0.48);

                        margin-top: 23PX;
                    }

                    .article_detail_content{
                        width: 744PX;
                        margin-top: 40PX;
                        margin-bottom: 40PX;
                    }

                }

                .not_article_wrap{
                    width: 100%;
                    min-height: 800PX;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .not_article{
                        display: flex;
                        align-items: center;

                        font-size: 20PX;
                        color: rgba($color: #000000, $alpha: 0.2);

                        .not_article_icon{
                            margin-right: 10PX;
                            font-size: 30PX;
                        }
                    }
                }
            }
        }
    }

</style>
