import { articleList, articleSearch } from '../../api'

export default {

    namespaced: true,
    // 重要信息：state 必须是一个函数，
    // 因此可以创建多个实例化该模块
    state: () => ({
        article_recommend_list: {},
        article_list: {},
        article_search_list: {},
        is_loading: false,
    }),

    actions: {

        fetchArticleRecommendList({ commit }){
            return articleList({type: "short_video_lesson_recommend"}).then(({data, status}) => {
                commit('updateArticleRecommendList', data.data.list)
            }).catch((e)=>{
                commit('updateArticleRecommendList', {})
            })
        },

        fetchArticleList({ commit }, route){

            let type = route.params.type ? route.params.type : 'short_video_lesson_news'
            let page = route.params.page ? route.params.page : 1

            return articleList({type: type, page: page}).then(({data, status}) => {
                commit('updateArticleList', data.data.list)
            }).catch((e)=>{
                commit('updateArticleList', {})
            }).finally(()=>{
                commit('updateIsLoading', false)
            })
        },

        fetchArticleSearch({ commit, rootState }, route){

            let keyword = route.params.keyword ? route.params.keyword : ''
            let page = route.params.page ? route.params.page : 1

            return articleSearch({keyword: keyword,
                                  types: JSON.stringify(rootState.search_types),
                                  page: page})
            .then(({data, status}) => {
                commit('updateSearchList', data.data.list)
            }).catch((e)=>{
                commit('updateSearchList', {})
            }).finally(()=>{
                commit('updateIsLoading', false)
            })
        },

    },

    mutations: {
        updateArticleLabList: (state, data) => state.article_lab_list = data,

        updateArticleRecommendList: (state, data) => state.article_recommend_list = data,

        updateArticleList: (state, data) => state.article_list = data,

        updateSearchList: (state, data) => state.article_search_list = data,

        updateIsLoading(state, isloading) {
            state.is_loading = isloading;
        },


    }
}
