import router from '../router'
import uuid from 'uuid/v1'

export default class context {


    static instance //类实例
    static app
    /**
     * 获取实例静态方法
     * @return {*}
     */
    static getInstance(){
        if(this.instance == null){
            this.instance = new context()
        }
        return this.instance
    }

    /**
     * 启动时设置app实例
     * @param app
     */
    setApp(app){
        this.app = app
    }

    getApp(){
        return this.app
    }

    /**
     * 根据文章ID 生成文章url相对链接
     * @param article_id
     * @return {string}
     */
    getArticleDetailURL(article_id){
        return this.getApp().$router.options.base+"pc_article_detail/"+article_id+".html"
    }

    /**
     * 获取设备ID，基于localStorage
     * @return {string}
     */
    getDeviceID(){

        if(!this.supportLocalStorage()){
            return null
        }

        let key = "foozo_douyin_proxy_website_device_id"
        let device_id = localStorage.getItem(key)

        if(!device_id){
            let uuid_str = uuid()
            localStorage.setItem(key, uuid_str)
            device_id = uuid_str
        }

        return device_id
    }

    /**
     * 检测是否支持localStorage
     * @return {boolean}
     */
    supportLocalStorage() {

        if(typeof window !== 'undefined'){
            console.log()
        }else {
            return false
        }

        if (navigator.userAgent.indexOf('UCBrowser') > -1) {
            return false;
        }
        let uaFlag = 0;
        let uaArr = new Array('Chrome', 'MQQBrowser', 'QQ', 'TBS', 'wxwork', 'MicroMessenger', 'T7', 'baiduboxapp', 'baidubrowser', 'MiuiBrowser', 'NetType', 'OPR');
        for (let i = 0; i < uaArr.length; i++) {
            if (navigator.userAgent.indexOf(uaArr[i]) > -1) {
                uaFlag = 1;
            }
        }
        if (uaFlag != 1) {
            if (navigator.userAgent.indexOf('HUAWEIEVA') > -1 || navigator.userAgent.indexOf('HUAWEIVTR') > -1) {
                return false;
            }
        }
        let testKey = 'test_supportLocalStorage';
        try {
            window.localStorage.setItem(testKey, 'testValue');
            window.localStorage.removeItem(testKey);
            return true;
        } catch (e) {
            return false;
        }
    }

}
