import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import article_list from './modules/article_list'
import article_detail from './modules/article_detail'

export default function createStore() {
    return new Vuex.Store({

        state: {
            token: "",
            cookies: null,
            search_types: ["short_video_lesson_news","short_video_lesson_recommend"],
            lab_list: [
                {
                    type: 'short_video_lesson_short_video_makes_money',
                    type_name: '短视频赚钱',
                },
                {
                    type: 'short_video_lesson_douyin_up_hot',
                    type_name: '抖音上热门',
                },
                {
                    type: 'short_video_lesson_short_video_realization',
                    type_name: '短视频变现',
                },
                {
                    type: 'short_video_lesson_short_video_operation',
                    type_name: '短视频运营',
                },
                {
                    type: 'short_video_lesson_short_douyin_developing_trend',
                    type_name: '抖音发展趋势',
                },
                {
                    type: 'short_video_lesson_short_video_data_analysis',
                    type_name: '短视频数据分析',
                },
                {
                    type: 'short_video_lesson_short_video_shooting',
                    type_name: '短视频拍摄',
                },
                {
                    type: 'short_video_lesson_short_video_script',
                    type_name: '短视频脚本',
                },
                {
                    type: 'short_video_lesson_douyin_explosion_case',
                    type_name: '抖音爆款案例',
                },
                {
                    type: 'short_video_lesson_weight_maintenance_numbers',
                    type_name: '养号权重',
                },
                {
                    type: 'short_video_lesson_douplus_skill',
                    type_name: 'dou+投放技巧',
                },
                {
                    type: 'short_video_lesson_blue_vip_apply',
                    type_name: '蓝V申请',
                },
                {
                    type: 'short_video_lesson_short_video_clip',
                    type_name: '短视频剪辑',
                },
                {
                    type: 'short_video_lesson_bgmusic_skill',
                    type_name: 'BGM技巧',
                },

            ],
        },

        mutations: {
            updateCookies(state, cookies) {
                state.cookies = cookies;
            }
        },

        actions: {

        },

        modules: {
            article_list,
            article_detail,
        },

        strict: process.env.NODE_ENV !== 'production' // 线上环境关闭store检查
    })
}
