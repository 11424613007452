import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home'
import ArticleList from '../views/ArticleList'
import ArticleSearchResult from '../views/ArticleSearchResult'
import ArticleDetail from '../views/ArticleDetail'


import MobileHome from '../views/mobile/Home'

Vue.use(VueRouter)


export default function createRouter() {
    return new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes: [
            {
                path: '/',
                name: 'home',
                component: Home
            },
            {
                path: '/article_list/:type/:page',
                name: 'article_list',
                component: ArticleList
            },
            {
                path: '/article_search/:keyword/:page',
                name: 'article_search',
                component: ArticleSearchResult
            },
            {
                path: '/article_detail/:id',
                name: 'article_detail',
                component: ArticleDetail
            },


            {
                path: '/m',
                name: 'm_home',
                component: MobileHome
            },

        ]
    })
}
