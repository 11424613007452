<template>
    <div class="page_footer">

        <div class="page_footer_top">

            <div class="page_footer_top_content">

                <div class="page_footer_top_left">
                    <div class="page_footer_top_left_title">小卓运营官</div>
                    <div class="page_footer_top_left_desc">
                        <div> 我们是互联网新秀，每日更新短视频，实践干货，行业资讯、 </div>
                        <div style="margin-top: 5PX;">以及专业的短视频代运营，帮助您提高专业知识与工作效率。</div>
                    </div>
                </div>

                <div class="page_footer_top_contact">
                    <div class="page_footer_top_contact_icons">
                        <img class="page_footer_top_contact_icon" src="../assets/PageFooter/weixin.png" alt="">
                        <img class="page_footer_top_contact_icon" src="../assets/PageFooter/youjian.png" alt="">
                    </div>

                    <div class="page_footer_top_contact_desc">
                        <div>联系电话：13161309885</div>
                        <div style="margin-top: 5PX;">公司地址：北京市朝阳区酒仙桥路宏源大厦9层</div>
                    </div>
                </div>

                <img class="page_footer_top_weixin_qr" src="../assets/PageFooter/weixin_qr.png" alt="">

            </div>

        </div>

        <div class="page_footer_bottom">

            <div>Copyright  2015 北京凡卓互动科技有限公司（简称：凡卓传媒）All Rights Reserved</div>
            <div style="margin-top: 5PX;">京ICP备18052189号-1</div>

        </div>

    </div>
</template>

<script>
    export default {
        name: "PageFooter"
    }
</script>

<style lang="scss" scoped>

    .page_footer{
        width: 100vw;
        margin-top: 30PX;

        .page_footer_top{
            width: 100%;
            height: 210PX;
            background: #2A2A32;

            display: flex;
            justify-content: center;
            align-items: center;

            .page_footer_top_content{
                width: 1050PX;
                display: flex;
                justify-content: space-between;

                .page_footer_top_left{

                    .page_footer_top_left_title{
                        color: white;
                        font-size: 25PX;
                    }

                    .page_footer_top_left_desc{
                        font-size: 14PX;
                        color: rgba($color: #FFFFFF, $alpha: 0.6);
                        margin-top: 28PX;
                    }
                }

                .page_footer_top_contact{

                    .page_footer_top_contact_icons{
                        display: flex;
                        .page_footer_top_contact_icon{
                            width: 28PX;
                            height: 28PX;
                            margin-right: 10PX;
                        }
                    }

                    .page_footer_top_contact_desc{
                        font-size: 14PX;
                        color: rgba($color: #FFFFFF, $alpha: 0.6);
                        margin-top: 28PX;
                    }
                }

                .page_footer_top_weixin_qr{
                    width: 123PX;
                    height: 123PX;
                }
            }
        }

        .page_footer_bottom{
            width: 100vw;
            height: 91PX;
            background: #111111;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            color: #9FA4AF;
            font-size: 14PX;
        }
    }

</style>
