<template>
    <div class="content_wrap">

        <navigation-carousel/>

        <div ref="labs"></div>
        <labs-card
                :article_hot_lab_list="article_hot_lab_list"
                :article_lab_list="article_lab_list"/>

        <div class="main_content">

            <div class="page_content" ref="page_content">

                <search-article/>

                <page-article-list :title="article_list.type_name" :article_list="article_list"/>

            </div>

            <right-content :article_recommend_list="article_recommend_list" :right_lab_list="right_lab_list"/>
        </div>

        <page-footer/>
    </div>
</template>

<script>
    import { mapState } from 'vuex'


    import NavigationCarousel from '../components/NavigationCarousel'
    import LabsCard from '../components/LabsCard'
    import SearchArticle from '../components/SearchArticle'
    import PageArticleList from '../components/PageArticleList'
    import RightContent from '../components/RightContent'

    export default {
        name: "ArticleList",

        data(){
            return{

            }
        },

        created(){

        },

        watch:{
            '$store.state.article_list.article_list': (newFlag, oldFlag) => {
                // 需要执行的代码
            }
        },


        components: {
            NavigationCarousel,
            LabsCard,
            SearchArticle,
            PageArticleList,
            RightContent,
        },

        asyncData({store, route}){
            store.commit('article_list/updateIsLoading', true)

            /** 滑动上去 */

            setTimeout(()=>{
                try {
                    route.matched[0].instances.default.$refs.labs.scrollIntoView({block: 'start', behaviour: "instant"})
                }catch (e) {console.log(e)}
            }, 200)



            return Promise.all([
                store.dispatch("article_list/fetchArticleRecommendList"),
                store.dispatch("article_list/fetchArticleList", route),
            ])
        },

        computed: {
            ...mapState({
                right_lab_list: state => {
                    return state.lab_list
                },
                article_hot_lab_list: state => {
                    return state.lab_list.slice(0, 4)
                },
                article_lab_list: state => {
                    return state.lab_list.slice(4, state.lab_list.length)
                },
                article_list: state => state.article_list.article_list,
                article_recommend_list: state => {
                    if(state.article_list.article_recommend_list.data == undefined || state.article_list.article_recommend_list.data.length < 5){
                        return state.article_list.article_recommend_list
                    }else{
                        let article_recommend_list = JSON.parse(JSON.stringify(state.article_list.article_recommend_list))
                        article_recommend_list.data = article_recommend_list.data.slice(0, 4)
                        return article_recommend_list
                    }
                },
            })
        },

        // 重要信息：当多次访问路由时，
        // 避免在客户端重复注册模块。
        destroyed () {
            // this.$store.unregisterModule('home')
        },


        metaInfo: {
            title: '抖音培训-短视频培训-抖音怎么上热门-抖音培训课程-抖音涨粉技巧-抖音视频制作—小卓学院',
            meta: [
                {
                    name: 'keyWords',
                    content: '抖音培训课程，抖音培训，抖音涨粉，抖音涨粉技巧，短视频培训，短视频代运营，抖音带货，小卓学院'
                },
                {
                    name: 'Description',
                    content: '小卓运营官-为短视频创业助力、培养抖音教程技术人才，为全国人民提供最新的抖音培训和短视频课程，让你的作品能快速的上热门赚钱！'
                }
            ]
        },
    }
</script>

<style lang="scss" scoped>
    .content_wrap{
        width: 100vw;

        .center_content{
            width: 100%;
            display: flex;
            justify-content: center;
        }


        .main_content{
            display: flex;
            flex-direction: row;
            justify-content: center;

            margin-top: 37PX;

            .page_content{
                width: 780PX;
            }
        }
    }

</style>
