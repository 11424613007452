
// 创建Vue实例

import Vue from 'vue'
import App from './App.vue'
import context from './common/context'
import './common/ui.config'

import createRouter from './router/index.js'
import createStore from './store/index.js'
import { sync } from 'vuex-router-sync'


// console.log(window)



export default function createApp() {
    const router = createRouter()
    const store = createStore()

    // 准备挂载前时刷新数据
    Vue.mixin({
        beforeMount () {
            const { asyncData } = this.$options

            if (asyncData) {
                // 将获取数据操作分配给 promise
                // 以便在组件中，我们可以在数据准备就绪后
                // 通过运行 `this.dataPromise.then(...)` 来执行其他任务
                this.dataPromise = asyncData({
                    route: router.currentRoute,
                    store,
                })
            }
        }
    })

    sync(store, router)

    const app = new Vue({
        router,
        store,
        render: h => h(App),
    })
    context.getInstance().setApp(app)
    return {app, router, store}
}
