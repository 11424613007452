import axios from "axios";
import md5 from 'js-md5';
import context from './common/context'


export const host = 'https://api.article.foozo.cn/'


const request = axios.create({
    baseURL: host,
    headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    },
    timeout: 30 * 1000,
});

request.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        return Promise.reject(error);
    }
);

function sign(target) {
    let time = new Date().getTime();
    let key = "fd2ceac6b6000ed7e1676f264cdef869";

    let sign = md5( key +  time + target.data);

    target.headers = {
        "server-time": time,
        "server-date": sign,
    };
    return target;
}


async function addToken(target) {
    return Object.assign(sign(target), {
        headers: target.headers == null? {}:target.headers,
    });
}

export const paramify = async params => {

    params = Object.assign(params, await gateway_info())
    let ret = [];
    for (let i in params) {
        ret.push(i + "=" + str_trim(params[i]));
    }
    return ret.join("&");
};

export const str_trim = params => {
    if(params && typeof params === 'string'){
        return params.replace(/(^\s*)|(\s*$)/g, "");
    }else{
        return params
    }
};

async function gateway_info(){

    return new Promise((resolve, reject)=>{
        let data = {
            'device_id': context.getInstance().getDeviceID(),
        }
        resolve(data)
    })
}


export const URL_ARTICLE_LIST = '/api/article/list' //文章列表
export const URL_ARTICLE_SEARCH = '/api/article/search' //文章搜索
export const URL_ARTICLE_DETAIL = '/api/article/detail' //文章详情
export const URL_LEAVE_MESSAGE_PUT = '/api/leave_message/put' //提交留言


// 文章列表
export const articleList = async data => {
    return request(
        await addToken({ method: "post", url: URL_ARTICLE_LIST, data: await paramify(data) })
    );
};

// 文章搜索
export const articleSearch = async data => {
    return request(
        await addToken({ method: "post", url: URL_ARTICLE_SEARCH, data: await paramify(data) })
    );
};

// 文章详情
export const articleDetail = async data => {
    return request(
        await addToken({ method: "post", url: URL_ARTICLE_DETAIL, data: await paramify(data) })
    );
};

// 提交留言
export const putLeaveMessage = async data => {
    return request(
        await addToken({ method: "post", url: URL_LEAVE_MESSAGE_PUT, data: await paramify(data) })
    );
};
