<template>
    <div class="navigation_wrap">
        <div class="navigation">
            <a :href="$router.options.base"><img class="navigation_logo" src="../assets/logo.png" alt=""></a>


            <div class="navigation_items">
                <a :href="$router.options.base"><div class="navigation_item" @click="onTab(0)">首页</div></a>
                <a :href="$router.options.base"><div class="navigation_item" @click="onTab(0)">运营干货</div></a>
                <a :href="$router.options.base"><div class="navigation_item" @click="onTab(0)">抖音课程教学</div></a>
                <a :href="$router.options.base"><div class="navigation_item" @click="onTab(0)">抖音代运营</div></a>
                <a :href="$router.options.base"><div class="navigation_item" @click="onTab(0)">联系我们</div></a>
            </div>

        </div>
    </div>

</template>

<script>
    export default {
        name: "Navigation",

        created(){

        },
        methods: {
            onTab(index){
                this.$emit("onTab", index)
            }
        },
    }
</script>

<style scoped lang="scss">

    .navigation_wrap{
        width: 100%;
        display: flex;
        justify-content: center;

        .navigation{

            height: 85PX;
            min-width: 1050PX;

            display: flex;
            align-items: center;
            justify-content: space-between;

            background: rgba(0,0,0,0.05);

            .navigation_logo{
                width: 263PX;
                height: 49PX;
                /*margin-left: 285.25PX;*/
            }

            .navigation_items{
                display: flex;
                justify-content: space-between;
                align-items: center;

                a{
                    text-decoration: none;
                }

                .navigation_item{
                    font-size: 12PX;
                    color: white;
                    margin-left: 30PX;
                    cursor: pointer;

                }
            }
        }

    }


</style>
