<template>
    <div class="search_article">
        <div class="search_article">
            <input class="search_article_input" placeholder="搜索" type="text" v-model="keyword" @keyup.enter="onSearch" @blur="onFocus(false)" @focus="onFocus(true)"/>
            <img class="search_article_icon" src="../assets/Home/search.png" alt="" @click="onSearch">
        </div>

        <div v-if="is_focus && search_pre_list.length>0" class="search_pre_panel">

            <div class="search_pre_item" v-for="(item,index) in search_pre_list" :key="index">
                <i class="el-icon-tickets search_pre_item_icon"></i>
                <router-link  target="_blank" :to="{name:'article_detail', params:{id:item.id } }"   >{{ item.title }}</router-link>
            </div>

        </div>

    </div>
</template>

<script>
    import { articleSearch } from '../api'
    export default {
        name: "SearchArticle",
        props: ['default_value'],

        data(){
            return{
                keyword: this.default_value,
                search_pre_list: [
                    // {
                    //     id: 1,
                    //     title: '抖音的代收费模式是怎么样的',
                    // },
                ],
                is_focus: false,
            }
        },

        watch: {

            "keyword": function (newd) {
                if(newd){
                    this.querySearchData()
                }
            }
        },

        mounted(){
            setTimeout(()=>{
                this.keyword = this.default_value
            },1000)
        },


        methods: {

            querySearchData(){
                articleSearch(
                    {
                        keyword: this.keyword,
                        types: JSON.stringify(this.$store.state.search_types),
                        page: 1
                    }).then(({data, status}) => {
                           if(data.data.list.data){
                               this.search_pre_list = data.data.list.data
                           }
                        })
            },

            onSearch(){
                if(this.keyword){
                    this.onFocus(false)
                    this.$router.push({name: 'article_search', params:{keyword: this.keyword, page: 1}}).catch(err=>{err})
                }
            },

            onFocus(is_focus){
                if(is_focus){
                    this.is_focus = is_focus
                }else{
                    setTimeout(()=>{
                        this.is_focus = is_focus
                    },100)
                }

            },
        },
    }
</script>

<style lang="scss" scoped>
    .search_article{
        position: relative;
        .search_article{
            width: 750PX;
            height: 50px;
            background: #F2F2F2;
            border-radius: 360PX;

            display: flex;
            justify-content: space-between;
            align-items: center;

            .search_article_input{
                width: 670PX;
                height: 100%;
                background: none;
                border: none;
                outline:none;
                margin-left: 30PX;
                font-size: 21PX;
            }

            .search_article_input::-webkit-input-placeholder{
                color: #CDCDCD;
            }

            .search_article_icon{
                width: 28PX;
                height: 28PX;
                margin-right: 24PX;

            }
        }

        .search_pre_panel{
            position: absolute;
            z-index: 999;
            top: 60PX;
            width: 750PX;
            background: white;
            box-shadow:0px 11px 21px 0px rgba(91, 91, 91, 0.08);

            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;

            padding-top: 10PX;
            padding-bottom: 10PX;

            a{
                text-decoration: none;
                color: rgba(0,0,0,0.8);
            }

            .search_pre_item{
                width: 690PX;
                height: 50PX;

                display: flex;
                align-items: center;

                font-size: 15PX;
                color: rgba(0,0,0,0.8);

                .search_pre_item_icon{
                    margin-right: 10PX;
                }
            }
        }
    }
</style>
