import Vue from 'vue'
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

// import ViewUI from 'view-design';
// import 'view-design/dist/styles/iview.css';
// Vue.use(ViewUI);

import Navigation from '../components/Navigation'
Vue.component('Navigation', Navigation)

import PageFooter from '../components/PageFooter'
Vue.component('PageFooter', PageFooter)


if(typeof window !== 'undefined'){
    console.log("引入vue-awesome-swiper u")

    require('lib-flexible/flexible')

    const { swiper, swiperSlide } = require('vue-awesome-swiper')
    require('swiper/dist/css/swiper.css')

    Vue.component('swiper', swiper)
    Vue.component('swiperSlide', swiperSlide)


    const ElementUI = require('element-ui')
    require('../styles/element-variables.scss')
    Vue.use(ElementUI)

}
